import React from "react";
import { Link } from "react-router-dom";

import { scrollToSection } from "../../utils/scrollToSection";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <a href="#services" onClick={scrollToSection}>
          Services
        </a>
      </li>
      <li>
        <a href="#works" onClick={scrollToSection}>
          Works
        </a>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};

export default Nav;
